a:hover{
  color: #ff992e !important;
  background: transparent !important;
}

a{
  transition: color 150ms linear;
}


html{
  background: #3e3e3e;
}
.cover-foto {
  background: url('/img/macznl-nacht.jpg');
  /* Set a specified height, or the minimum height for the background image */
  min-height: 500px;

  /* Set background image to fixed (don't scroll along with the page) */
  background-attachment: fixed;

  /* Center the background image */
  background-position: center;

  /* Set the background image to no repeat */
  background-repeat: no-repeat;

  /* Scale the background image to be as large as possible */
  background-size: cover;
}

.home-icon {
  font-size: 3rem;
  margin: auto;
}

.home-box{
  background: rgba(255,255,255,85%);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 100%;
}

.home-box .content{
  font-size: 0.85em;
}



figure.has-in-shadow::after {
  box-shadow: inset 2px 2px 10px 1px rgba(0,0,0,80%);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.has-in-shadow::after {
  box-shadow: inset 2px 2px 10px 1px rgba(0,0,0,80%);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.home-box a{
  color: #4a4a4a;
  text-decoration: underline;
}
