a:hover {
  color: #ff992e !important;
  background: none !important;
}

a {
  transition: color .15s linear;
}

html {
  background: #3e3e3e;
}

.cover-foto {
  background: url("macznl-nacht.cee723e7.jpg") center / cover no-repeat fixed;
  min-height: 500px;
}

.home-icon {
  margin: auto;
  font-size: 3rem;
}

.home-box {
  -webkit-backdrop-filter: blur(5px);
  background: #ffffffd9;
  height: 100%;
}

.home-box .content {
  font-size: .85em;
}

figure.has-in-shadow:after, .has-in-shadow:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  box-shadow: inset 2px 2px 10px 1px #000c;
}

.home-box a {
  color: #4a4a4a;
  text-decoration: underline;
}
/*# sourceMappingURL=index.a13a7615.css.map */
